import React from 'react';
import { useStoreState } from 'easy-peasy';

export const Box = ({ boxData,authuser }) => {
    const getUserValue = useStoreState(state => state.user.userInfo);
    let ctaUrl = authuser ? boxData.ctaUrl:boxData.anonUrl;

    return (
        <div className="col-md-4 home-tiles">
            <div className={`pnc-travel-box ${getUserValue.FirstName ? "" : "unauth"}`}>
                {getUserValue.FirstName &&
                    <div className="pnc-travel-icon">
                        <img src={boxData.src} alt={boxData.alt} />
                    </div>
                }
                <div className="pnc-travel-text text-center">
                    <h4>{getUserValue.FirstName ? boxData.title : boxData.unauthTitle}</h4>
                    <div className={`inner-para ${getUserValue.FirstName ? "" : "unauth"}`}><p dangerouslySetInnerHTML={{ __html: getUserValue.FirstName ? boxData.desc : boxData.unauthDesc}}></p></div>
                </div>
                <a href={getUserValue.FirstName ? global.catalogUrl+ctaUrl : boxData.unauthUrl}>
                <button aria-label={getUserValue.FirstName ? boxData.btnText : boxData.btnTextUnauth} className="btn">{getUserValue.FirstName ? boxData.btnText : boxData.btnTextUnauth}</button>
                </a>
            </div>
        </div>
    )
}



const TwoBox = ({ boxData }) => {
    
    const getUserValue = useStoreState(state => state.user.userInfo)

    return (
        <section className={getUserValue.FirstName ? 'pnc-twobox-section sign-up-twobox' : 'pnc-twobox-section'}>
            <div className="container">
                <div className="pnc-grey-box-content">
                    {
                        getUserValue.FirstName ? 
                    
                    <div className="row justify-content-center">
                        <div className="col-md-8 home-up-tiles">
                            <div className="pnc-card-image-wrapper">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="pnc-media">
                                            <h5 className="mt-0 mb-1">WELCOME, { `${getUserValue.FirstName},` }</h5>
                                            <p className="reward-point">you have { getUserValue.JsonExternalData.CurrentBalance ? parseInt(getUserValue.JsonExternalData.CurrentBalance).toLocaleString(): 0} points!</p>
                                            <p className="reward-desc">PNC Cash Unlimited&reg;<br/> Visa Signature&reg; Credit&nbsp;Card</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <img className="img-responsive" src="./images/PNC_CashBack.png" alt="pnc-cash-unlimited-card" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : '' }
                    
                    <div className="row justify-content-center">
                        {
                            boxData.map((el, index) => {
                                return <Box boxData={el} key={index} authuser={getUserValue.FirstName ? true : false}/>
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TwoBox;
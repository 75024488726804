import React from 'react';
import { useHistory } from "react-router-dom";

const Notfound = (props) => {
    document.title = props.title;
    let history = useHistory();

    return (
        <section className="pnc-contact-us">
            <div className="container">
                <div className="row">
                    <div className="offset-md-1 col-md-11">
                        <div className="contact_us">

                            <div className="alert alert-danger" role="alert">
                                path '{history.location.pathname}' was not found
                            </div>

                            <h3 aria-level="1">THANK YOU FOR VISITING PNC CASH UNLIMITED</h3>

                            <h5>An error occurred when you requested this page.</h5>

                            <p>We apologize for the inconvenience this may have caused you.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Notfound;
import React, { useState,useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './ordermodal.css';

const Deletemodal = (props) => {
  const {
    deleteMessages,
    togglePopup,
    setPopupState,
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => {
      setModal(!modal);
      setPopupState();
    }

  useEffect(() => {
      if(togglePopup){
        setModal(true)
      }
   
  },() => {
    setModal(false)
  }
  );

 


  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="order-detail-popup modal-sm">
        <ModalHeader toggle={toggle}>Message Delete</ModalHeader>
        <ModalBody>
          <div className="row">
              <div className="col-md-12">
              Are you sure you want to delete the selected message(s)?
              </div>
          </div>
        </ModalBody>
        <ModalFooter>
        <Button color="primary" onClick={deleteMessages}>Yes</Button>  
          <Button color="primary" onClick={toggle}>No</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Deletemodal;
import { createStore, action, thunk, persist } from "easy-peasy";
import axios from "axios";

import {read_cookie} from "../read_cookie"
import config from "../configs/config";

const URL = config.qa.node_url;

const post = async (apiUrl, requestData, header) => {
  return await axios
    .post(URL + apiUrl, requestData, {
      headers: {
        token: header,
        programcode: "RTL_TVL",
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const Store = createStore(
  persist(
    {
      token: {
        tokens: "",
        getToken: thunk(async (actions, payload) => {
          const updated = read_cookie("pnc-rewards-token");
          actions.setToken(updated);
        }),
        setToken: action((state, payload) => {
          // if(payload.hasOwnProperty('data')){
            if(payload){
          sessionStorage.setItem("accessToken", read_cookie("pnc-rewards-token"));
          state.tokens = payload.data;
            }
          // }
        }),
      },
      user: {
        userInfo: "",
        getUser: thunk(async (actions, payload, state) => {
          let requestData = {
            profileId: read_cookie("profileId"),
          };
          const updated = await post("getProfileById", requestData, payload);
          actions.setUser(updated);
        }),
        setUser: action((state, payload) => {
          // localStorage.userInfo = JSON.stringify(payload.data);
          state.userInfo = payload.data;
        }),
      },
      transcations: {
        transcationsInfo: "",
        getTranscations: thunk(async (actions, payload, state) => {
          let requestData = {
            ProfileId: read_cookie("profileId"),
          };
          const updated = await post("transcations", requestData, payload);
          actions.setTranscations(updated);
        }),
        setTranscations: action((state, payload) => {
          state.transcationsInfo = payload.data;
        }),
      },
      messages: {
        messagesInfo: "",
        getMessages: thunk(async (actions, payload, state) => {
          let requestData = {
            ProfileId: read_cookie("profileId"),
          };
          const updated = await post(
            "getProfileEmailMessages",
            requestData,
            payload
          );
          actions.setmessages(updated);
        }),
        setmessages: action((state, payload) => {
          state.messagesInfo = payload.data;
        }),
      },
    },
    { storage: "" }
  )
);

export default Store;
import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./ordermodal.css";

const Ordermodal = (props) => {
  const { order, togglePopup, setPopupState, className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setPopupState();
  };

  useEffect(
    () => {
      if (togglePopup) {
        setModal(true);
      }
    },
    () => {
      setModal(false);
    }
  );

  const custData = [order].map((val) => {
    return (
      <>
        <p>{val.ShipToName}</p>
        <p>{val.AddressLine1}</p>
        <p>{val.AddressLine2}</p>
        <p>{val.AddressLine3}</p>
        <p>{val.City}</p>
        <p>{val.State}</p>
        <p>{val.PostalCode}</p>
      </>
    );
  });

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="order-detail-popup modal-lg"
      >
        <ModalHeader toggle={toggle}>Order Detail</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-md-4 col-form-label"
                >
                  Order #:
                </label>
                <div className="col-md-8">
                  <div className="form-control-plaintext">
                    {order.OrderNumber}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-md-4 col-form-label"
                >
                  Status:
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    disabled
                    className="form-control-plaintext"
                    value={order.OrderStatus}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-md-4 col-form-label"
                >
                  Customer:
                </label>
                <div className="col-md-8">
                  <div className="form-control-plaintext">{custData}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-md-4 col-form-label"
                >
                  Reward:
                </label>
                <div className="col-md-8">
                  <div className="form-control-plaintext">
                    {order.ItemDescription}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-md-4 col-form-label"
                >
                  Qty:
                </label>
                <div className="col-md-8">
                  <div className="form-control-plaintext">
                    {order.OrderQuantity}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-md-4 col-form-label"
                >
                  Item #:
                </label>
                <div className="col-md-8">
                  <div className="form-control-plaintext">
                    {order.ItemNumber}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="staticEmail"
                  className="col-md-4 col-form-label"
                >
                  Points:
                </label>
                <div className="col-md-8">
                  <div className="form-control-plaintext">
                    {order.Points !== ""
                      ? parseInt(order.Points).toLocaleString()
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close Window
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Ordermodal;

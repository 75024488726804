import React from 'react';
import Carousel from 'react-bootstrap/Carousel'

const BonusPoint = () => {
    return (
        <section className="pnc-bonus-points">

            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <div id="slide-wrapper" className="carousel slide" data-ride="carousel">

                            <Carousel indicators={false}>
                                <Carousel.Item>
                                    <a href="#">
                                        <img className="img-responsive hide_mobile" src="https://place-hold.it/614x200"
                                            alt="placeholder" />
                                        <img className="img-responsive show_mobile" src="https://place-hold.it/350x400"
                                            alt="placeholder" />
                                    </a>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <a href="#">
                                        <img className="img-responsive hide_mobile" src="https://place-hold.it/614x200"
                                            alt="placeholder" />
                                        <img className="img-responsive show_mobile" src="https://place-hold.it/350x400"
                                            alt="placeholder" />
                                    </a>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <a href="#">
                                        <img className="img-responsive hide_mobile" src="https://place-hold.it/614x200"
                                            alt="placeholder" />
                                        <img className="img-responsive show_mobile" src="https://place-hold.it/350x400"
                                            alt="placeholder" />
                                    </a>
                                </Carousel.Item>
                            </Carousel>
                        </div>


                    </div>
                </div>
            </div>

        </section>
    )
}

export default BonusPoint;
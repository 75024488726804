import React, { Component, useState } from "react";
import { Collapse } from "react-bootstrap";
import ModalExample from "./faqmodal";
import $ from "jquery";

export default class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      questions: [
        {
          id: 5,
          question_title: "Account Activity",
          qa: [
            {
              question:
                "How can I access my PNC Cash Unlimited Reward Program information?",
              answer:
                "You can access your PNC Cash Unlimited Reward Program details from the Rewards Center in PNC Online Banking. This is a simple way to manage your PNC Cash Unlimited Account information without any additional login required. Additionally, you can access your PNC Cash Unlimited Account information by calling 1-800-558-8472.",
            },
            {
              question:
                "What do I do if I forgot my password for this PNC Cash Unlimited rewards site?",
              answer:
                "If you have forgotten your password, go to Forgot Password for instructions or <a href='/contact'>contact us</a>.",
            },
          ],
        },
        {
          id: 8,
          question_title: "Security and Data Use",
          qa: [
            {
              question: "Is redeeming points online secure?",
              answer:
                "We highly value our cardholders' security and have made points redemption on the PNC Cash Unlimited Reward Program website extremely safe. Please read our Security & <a href='https://www.pnc.com/en/privacy-policy.html' target='_blank'>privacy policy</a> for more information.",
            },
            {
              question:
                "What are cookies and does the PNC Cash Unlimited website use them?",
              answer:
                "A cookie is a small data file that contains a unique identifier. Cookies can be used to store information about you and your web server session on your hard drive. Cookies can also be used to link your computer to information stored in a separate location.  See <a href='https://www.pnc.com/en/privacy-policy.html' target='_blank'>privacy policy</a> for a description of the PNC Cash Unlimited program use of cookies.",
            },
            {
              question:
                "How do I know the emails I get about PNC Cash Unlimited are legitimate?",
              answer:
                "PNC Bank will send you emails for your PNC Cash Unlimited account that will include PNC Cash Unlimited in the sender's name. We will NEVER ask you for personal information or to verify anything about your PNC Cash Unlimited account by email. Requests like these are often scams and PNC Bank takes great care to avoid sending any email that could be confused with these scams. If you receive an email purporting to be from PNC Bank or one of its affiliates that you believe is not legitimate, please forward it to abuse@pnc.com.",
            },
            // {
            //     question: "Why do I need to set up security questions?",
            //     answer: "Security questions are another way to verify who you are. For example, security questions allow you to change sensitive profile information such as your PNC points Username or password without having to <a href='/contact'>contact us.</a>"
            // },
            {
              question:
                "What if I cannot remember the answer to my security questions?",
              answer:
                "If you do not answer your security questions correctly or cannot remember the answers, you will need to <a href='/contact'>contact us.</a>",
            },
          ],
        },
        /* {
          id: 12,
          question_title: "Username and Profile",
          qa: [
            {
              question: "Why am I being asked to create a username?",
              answer:
                "You can find your PNC Premier Traveler Reward Program information on the Rewards Center page in PNC Online Banking and do not need to set up a new username and password. Click on your card to access rewards information, and to redeem points.?However, you must have a unique username to login directly to the PNC Premier Traveler Reward Program website. Go to Create Username to create your username. It's quick and simple to do. You'll need your Card Number to begin.",
            },
            {
              question: "Can I create my own username?",
              answer:
                "Yes, you can. Your username needs to be at least eight but no more than 100 alphanumeric characters long. It is also case sensitive and may contain the following special characters: ! $ % , + - . : = ? { } _ [ ] ~ @. Your username cannot contain five or more consecutive digits of your Card number, cannot contain spaces, and cannot be the same as your password.",
            },
            {
              question: "Can I use my email address as my username?",
              answer:
                "Yes, as long as your email address meets the following requirements:<ul> <li>It's unique</li><li>It's between 8-100 alphanumeric characters</li><li>Does not contain five or more consecutive digits of your card number</li><li>Does not contain spaces</li><li>It's not the same as your existing password</li></ul>Go to Create Username to start the process. It's quick and simple to do. You'll need your card number to begin.",
            },
            {
              question:
                "If I'm accessing my PNC Premier Traveler Reward Program information from the Rewards Center in PNC Online Banking, do I need to create a separate username and password for this PNC Premier Traveler Reward Program site?",
              answer:
                "No. You can access your information simply and conveniently from the Rewards Center in PNC Online Banking.",
            },
            {
              question:
                "I have more than one PNC Premier Traveler account. Will my username work for both?",
              answer:
                "No. Your username cannot be assigned to multiple PNC Premier Traveler Reward Programs. You will need to create a unique username for each PNC Premier Traveler Reward Program.",
            },
            {
              question:
                "I typically use the automated phone system and now want to use the website. Do I need a username? I'm already in the program",
              answer:
                "You will not need a username to access your PNC Premier Traveler Reward Program information on the Rewards Center page in PNC Online Banking. Simply use your Online Banking username and password as you normally would to access PNC Online Banking.<br/><br/>However, you will need a username to access your PNC Premier Traveler Reward Program directly on the PNC Premier Traveler Reward Program website. If you don't have one, you will need to create a username. Or, if you have forgotten your username, you can retrieve it.<br/><br/>Please note, the username is only used to access your PNC Premier Traveler Reward Program on the PNC Premier Traveler Reward Program website. You will not need the username to log in through the Rewards Center in PNC Online Banking or the automated phone system.",
            },
            {
              question: "I forgot my username. Can you tell me what it is?",
              answer: "If you forgot your username, go to Retrieve Username.",
            },
            {
              question: "Why am I being asked to change my password?",
              answer:
                "To enhance password security, we have increased the minimum number of characters for all new passwords in the rewards program from 5 characters to 8. Although you are not required to change your existing password at this time, PNC strongly encourages all PNC Premier Traveler Reward Program participants to have passwords containing at least 8 (and up to 15) characters.",
            },
            {
              question: "Why is password security important?",
              answer:
                "Information to which you have access as a PNC Premier Traveler Reward Program participant, including information about your recent card transactions, is sensitive and confidential in nature and your password is the means to access this important information. While a secure and strong password may be more difficult for you to remember, you will find that it will be worth the extra effort to better protect your PNC Premier Traveler Reward Program.",
            },
            {
              question:
                "What types of passwords are strongest and most secure?",
              answer:
                "When you create a password, don't use personal information such as your name, address or birth date; a spouse or significant other's name; children's names and/or birth dates; pet's name; favorite sports team; mother's maiden name; or town where you grew up. It is a good idea to combine numbers and letters when creating a password. Use a word or phrase with meaning for you (so that it will be easy to remember), but mix in numbers and capital letters to increase the security of the password.<br/><br/><ul><li>Examples of phrases:<br/><br/>&quotYankee Doodle went to town&quot could be expressed in a password as: Ydwt4t<br/><br/>&quotI love Paris in the springtime&quot could be expressed in a password as: iLP1nST<br/><br/></li><li>Examples of words:<br/><br/>&quotartworks&quot could be expressed in a password as: 1rtWork6<br/><br/>&quotding dong&quot could be expressed in a password as: D1ngd0ng</li></ul>Please Note: These examples are intended to provide you with some ideas about how to create a password. You should not copy any of them when you create your password. To create your personal password, choose a phrase or word that has special meaning for you and format it using your own combination of letters and numbers.Never tell anyone else what your password is and never write your password down in an unsecured place!",
            },
            {
              question:
                "What are the rules for new passwords for PNC Premier Traveler?",
              answer:
                "Your new password must be 8-15 characters in length containing at least three of the following four character types – (1) capital letter, (2) lower case letter, (3) number, and (4) special character (such as ! $ % , + - . : = ? { } _ [ ] ~ @). No spaces are allowed. You cannot use four or more consecutive numbers that match the number of your PNC Premier Traveler card. In addition, the password is case sensitive.",
            },
            {
              question: "I forgot my password. How do I reset it?",
              answer:
                "Go to forgot password to reset or <a href='/contact'>contact us</a> for assistance.",
            },
          ],
        }, */
        {
          id: 10,
          question_title: "Customer Service",
          qa: [
            {
              question: "What happens if my card is lost or stolen?",
              answer:
                "If your card is lost or stolen, contact PNC Bank at 1-800-558-8472. If PNC Bank provides you with a replacement card, you will not lose the points in your PNC Cash Unlimited Reward Program and you will continue to earn points, even if that replacement card has a different card number.",
            },
            {
              question:
                "How can I get additional information about PNC Cash Unlimited?",
              answer:
                "For more information about PNC Cash Unlimited, please refer to <a target='_blank' href='https://www.pnc.com/en/personal-banking/banking/credit-cards/pnc-cash-unlimited-visa-credit-card.html'>pnc.com/cashunlimited</a> or to the PNC Cash Unlimited <a target='_blank' href='https://www.pnc.com/content/dam/pnc-com/pdf/personal/CreditCards/cash-unlimited-terms-conditions.pdf'>terms and conditions</a>.",
            },
            // ,{
            //     question: "Why isn't my browser working with your site?",
            //     answer: "To access information online, you need an Internet browser (Microsoft® Internet Explorer version 8.0 or higher, Firefox version 14.0 or higher, Safari version 6.0 or higher) supporting 128-bit encryption and an email account. If you use an older version of one of these browsers (including any versions of Netscape Navigator and AOL®), you could experience technical difficulties with activities on the site. You may download the latest versions of these browsers from the links below:<br><br> <a href='http://windows.microsoft.com/en-US/internet-explorer/downloads/ie' target='_blank'>Download Internet Explorer</a><br> <a href='http://www.mozilla.org/en-US/firefox/update/' target='_blank'>Download Firefox</a><br> <a href='http://www.macupdate.com/app/mac/15675/apple-safari' target='_blank'>Download Safari</a><br><br> For specific information on enabling or disabling your pop-up messages, please see the help section on your browser.<br> If you continue experiencing problems, please <a href='/contact'>contact us.</a>"
            // }
            {
              question: "Why isn't my browser working with your site?",
              answer:
                "To access information online, you need an Internet browser (Microsoft® Internet Explorer version 8.0 or higher, Firefox version 14.0 or higher, Safari version 6.0 or higher) supporting 128-bit encryption and an email account. If you use an older version of one of these browsers (including any versions of Netscape Navigator and AOL®), you could experience technical difficulties with activities on the site. You may download the latest versions of these browsers from the links below:<br><br> <a href='http://windows.microsoft.com/en-US/internet-explorer/downloads/ie' target='_blank'>Download Internet Explorer</a><br> <a href='http://www.mozilla.org/en-US/firefox/update/' target='_blank'>Download Firefox</a><br> <a href='http://www.macupdate.com/app/mac/15675/apple-safari' target='_blank'>Download Safari</a><br><br> For specific information on enabling or disabling your pop-up messages, please see the help section on your browser.<br> If you continue experiencing problems, please <a href='/contact'>contact us.</a>",
            },
          ],
        },
        {
          id: 9,
          question_title: "General Travel Information",
          qa: [
            {
              question: "May I redeem my points online?",
              answer:
                "Yes. All travel products can be either redeemed or requested online. For more information on travel redemption rules please <a href='/docs/ProgramRulesTravel.pdf' target='_blank'>click here.</a>",
            },
            {
              question:
                "Are there any fee(s) associated with travel redemptions?",
              answer:
                "Fees apply for travel booked using the phone with a PNC Travel Specialist. If you would like more information about fees, charges, costs, and taxes, please <a href='/contact'>contact us</a>.",
            },
            {
              question: "Can I pay redemption fee(s) with points?",
              answer:
                "Yes. As you go through the redemption process you will be offered the opportunity to pay your redemption fee using points, as applicable.",
            },
            {
              question: "Can I use my points for someone else to travel?",
              answer:
                "Yes. The PNC PNC Cash Unlimited participating cardholder may make reservations in the name of the person of his/her choice. Please ensure that the traveler information you enter during the booking process is an exact match to the travel documents that are required to fulfill the reservation (i.e. driver’s license, passport, etc.). Errors may result in delays and/or possible denial of the travel reservation.",
            },
            {
              question:
                "I did not get my email confirmation, what should I do?",
              answer:
                "To ensure receipt of your travel confirmation email, please ensure that the email address you provide at the time of booking is a current and valid email address. You may also want to check your spam/junk folder if you have not added us to your safe senders list. If you still do not find your email confirmation, please <a href='/contact'>contact us</a>.",
            },
            {
              question:
                "May I combine or transfer points to/from an airline, hotel or car rental frequent traveler program?",
              answer:
                "No. You may not transfer points from PNC Cash Unlimited to another reward program or combine points from PNC Cash Unlimited with points from another rewards program.",
            },
            {
              question:
                "Can I earn frequent flyer points or frequent traveler points for my PNC Cash Unlimited reward redemption?",
              answer:
                "Depending on the travel provider, you might accrue additional points in their program as a result of your PNC Cash Unlimited reward redemption. Please check with the individual provider or <a href='/contact'>contact us</a> .",
            },
            {
              question:
                "Do you offer any insurance for cancelation or trip interruption?",
              answer:
                "Yes. Please <a href='/contact'>contact us</a> for a quick quote.",
            },
            {
              question:
                "I have to cancel my reservation. Can I have my points reinstated or obtain a refund?",
              answer:
                "No, once redeemed, points for canceled reservations may not be reinstated to your PNC Cash Unlimited account. No credit will be given for unused portions of the reward. However, if trip insurance was purchased for the reward, refunds for covered cancelations may be available. Please <a href='/contact'>contact us</a> for additional questions.",
            },
            {
              question:
                "I have to cancel my reservation. Can I get the charges that were made to my card for the travel refunded?",
              answer:
                "No, once the reservation has been made for the travel reward, any card charges for that reward cannot be refunded to you. No credit will be given for unused portions of the reward. However, if trip insurance was purchased and charged to your card for the reward, a refund of that card charge for covered cancelations may be available. Please <a href='/contact'>contact us</a> for additional questions.",
            },
            {
              question: "What is \"split pay\" (points and dollars)?",
              answer:
                "Where available, the PNC Cash Unlimited \"split pay\" feature allows you to redeem your travel reward with a combination of points and a debit, credit or prepaid card payment. You are required to use the minimum number of points, as specified by PNC Cash Unlimited and, once the minimum mile requirement has been met, the remaining cost of the travel reward can be paid for either with additional points or with a credit, debit or prepaid card payment. The travel reward can also be redeemed in full using only points.",
            },
            {
              question:
                "Can I use a card other than the card I have enrolled in PNC Cash Unlimited for \"split pay\" (points and dollars) redemption?",
              answer:
                "Yes, you can use any debit, credit or prepaid card for a \"split pay\" reward redemption.",
            },
          ],
        },
      ],
    };
  }

  componentDidMount() {
    var parent = this;
    $(".purchase").click(function () {
      parent.setState({
        showModal: true,
      });
    });
  }

  updateSt = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { questions } = this.state;
    return (
      <section className="pnc-faq pnc-profile">
        <div className="container">
          <div className="row">
            <div className="offset-md-2 col-md-8">
              <div className="faq">
                <h3 aria-level="1">Frequently Asked Questions</h3>
                <div>
                  {questions.map((question, index) => (
                    <Questions key={index} {...question} />
                  ))}
                </div>
                {this.state.showModal ? (
                  <ModalExample
                    currSt={this.state.showModal}
                    updateSt={this.updateSt}
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export const Questions = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="collapse-wrapper">
      <div
        onClick={(e) => {
          setOpen(!isOpen);
        }}
        className={`faq-title ${isOpen ? "expanded" : ""}`}
      >
        {props.question_title}
      </div>
      <Collapse in={isOpen}>
        <div>
          <div className="faq-content">
            {props.qa &&
              props.qa.map((qa, index) => {
                return (
                  <div className="qa-wrapper" key={index}>
                    <div className="question">{qa.question}</div>
                    <div
                      dangerouslySetInnerHTML={{ __html: qa.answer }}
                      className="answer"
                    ></div>
                  </div>
                );
              })}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

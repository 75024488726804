import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useStoreState, useStoreActions, Actions } from "easy-peasy";
import $ from "jquery";
import IdleMonitor from "../idleMonitor";

import {read_cookie} from "../../read_cookie"
const Header = () => {
  const getTokenCall = useStoreActions((actions) => actions.token.getToken);
  const getUserInfo = useStoreActions((actions) => actions.user.getUser);
  const tokenValue = useStoreState((state) => state.token.tokens);
  const getUserValue = useStoreState((state) => state.user.userInfo);
  const getMessagesac = useStoreActions(
    (actions) => actions.messages.getMessages
  );
  const getMessagesValue = useStoreState(
    (state) => state.messages.messagesInfo
  );
  const [unreadCount, setunreadCount] = useState(0);

  useEffect(() => {
    var fullUrl = window.location.href;
    var fullArr = fullUrl.split("?");
    if (fullArr[1]) {
      if (localStorage.userInfo) {
        localStorage.clear();
        window.location.reload();
      }
    } else {
      getTokenCall();
    }

    // Update the document title using the browser API
    $(document).ready(function () {
      var width = $(window).width();
      var count = 0;
      if (width < 1000) {
        $(".dropdown-toggle").dropdown();
        $(".pnc-sign-in .dropdown-toggle").on("click tap", function () {
          $(".logged-out").toggle();
        });
      } else {
        $(".pnc-sign-in").hover(
          function () {
            $(".pnc-sign-in a").addClass("active");
          },
          function () {
            $(".pnc-sign-in a").removeClass("active");
          }
        );

        $(".pnc-sign-in").mouseout(function () {
          if ($(".logged-out").is(":visible")) {
            if ($(".logged-out").hasClass("show")) {
              $(".pnc-sign-in a").addClass("active");
              $(".logged-out").show();
            }
          }
        });
        $(document).click(function () {
          $(".pnc-sign-in a").removeClass("active");
        });
      }

      $(".menu-icon-container").click(function () {
        var activeList = $(".navbar-collapse.bg-dark.collapse.show").length;
        if (!(activeList >= 1)) {
          $(this).addClass("active-menu");
        } else {
          $(this).removeClass("active-menu");
        }
      });

      // Function to toggle all check box on header check.
      $(".selectall").click(function () {
        if ($(this).is(":checked")) {
          $("input:checkbox").prop("checked", true);
        } else {
          $("input:checkbox").prop("checked", false);
        }
      });

      $('[data-toggle="tooltip"]').tooltip();
      // Click function to expand sort content and toggle sort icon.
      $(".sort").click(function () {
        $(".pnc-sort-mb").slideToggle("slow");
        $(this).toggleClass("expand");
      });
      // Click function to count checkbox icon.
      var countChecked = function () {
        var n = $(".mAlernate input:checked").length;
        var totalNum = $(".wrap-reward-messages .mAlernate input").length;
        /* PO-213 : Rewrote this to remove message delete button 
          implementation in order to keep it always hidden.
        if (n > 0) {
          $(".pnc-delete").show();
        } else {
          $(".pnc-delete").hide();
          $(".pnc-reward-message-table input:checkbox").prop("checked", false);
        }*/
        if(!n) {
          $(".pnc-reward-message-table input:checkbox").prop("checked", false);
        }
        if (totalNum != n) {
          $(".pnc-reward-message-table input:checkbox").prop("checked", false);
        } else {
          $(".pnc-reward-message-table input:checkbox").prop("checked", true);
        }
        $(".pnc-message-count-num").text("(" + n + " Selected)");
      };
      countChecked();
      $(".wrap-reward-messages input[type=checkbox]").on(
        "change",
        countChecked
      );

      $(".all-select").click(function () {
        //	$('.pnc-reward-wrapper input:checkbox').prop('checked', true);
        var $checkbox = $(".pnc-reward-wrapper input:checkbox");
        var checkBoxes = $("input[type='checkbox']");
        checkBoxes.prop("checked") == true
          ? checkBoxes.prop("checked", false)
          : checkBoxes.prop("checked", true);
        countChecked();
      });
    });
    getUserInfo(read_cookie("pnc-rewards-token"));
    //getMessagesac(read_cookie("token"));
  }, []);

  const [menuClick, setMenu] = useState(false);

  // if (read_cookie("token") && !getUserValue.FirstName) {
  //   getUserInfo(read_cookie("token"));
  // }

  if (getMessagesValue && getMessagesValue.length > 1) {
    let unreadArr = getMessagesValue.filter((msg) => {
      return msg.IsRead === "N";
    });
    localStorage.unreadMsgCount = unreadArr.length;
    if (unreadArr.length !== unreadCount) {
      setunreadCount(unreadArr.length);
    }
  }

  const updateMessagesCount = () => {
    getMessagesac(read_cookie("pnc-rewards-token"));
  };

  const toggleDropdown = () => {
    setMenu(!menuClick);
  };

  return (
    <div>
      <div className="menu-overlay"></div>
      {getUserValue.FirstName ? <IdleMonitor></IdleMonitor> : ""}
      <header>
        <div className="container">
          <div className="d-flex justify-content-start">
            <ul className="sigup">
              <li>
                <a
                  href="https://www.pnc.com/en/personal-banking.html"
                  target="_blank"
                  aria-label="PNC.com"
                >
                  PNC.com
                </a>
              </li>
              <li>
                <a
                  href="https://www.pnc.com/en/personal-banking.html"
                  target="_blank"
                  aria-label="Personal"
                >
                  Personal
                </a>
              </li>
              <li>
                <a
                  href="https://www.pnc.com/en/small-business.html"
                  target="_blank"
                  aria-label="Small Business"
                >
                  Small Business
                </a>
              </li>
              <li>
                <a
                  href="https://www.pnc.com/en/corporate-and-institutional.html"
                  target="_blank"
                  aria-label="Corporate &amp; Institutional"
                >
                  Corporate &amp; Institutional
                </a>
              </li>
              <li>
                <a
                  href="https://www.pnc.com/en/about-pnc.html"
                  target="_blank"
                  aria-label="About Us"
                >
                  About Us
                </a>
              </li>
              <li className="only-sign-up name-signup text-right">
                {getUserValue.FirstName ? (
                  <a href="#" aria-label="points">
                    {" "}
                    <span>Hi, {getUserValue.FirstName} </span>
                    <br />
                    <span>
                      <strong>
                        {getUserValue.JsonExternalData.CurrentBalance
                          ? parseInt(
                              getUserValue.JsonExternalData.CurrentBalance
                            ).toLocaleString()
                          : 0}{" "}
                        Points
                      </strong>
                    </span>
                  </a>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        </div>
      </header>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <div
            className={
              menuClick
                ? "menu-icon-container active-menu"
                : "menu-icon-container"
            }
          >
            <button
              className={
                menuClick ? "navbar-toggler" : "navbar-toggler collapsed"
              }
              onClick={toggleDropdown}
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
              <span className="sr-only">Toggle navigation</span>
            </button>
          </div>

          <div className="menu-logo-container">
            <Link to="/">
              <img
                className="nav-logo"
                src="/images/PNC-Cash-Unlimited-Reversed.svg"
                alt="PNC Cash Unlimited logo"
              />
            </Link>
          </div>

          <div
            id="navbarCollapse"
            className={
              menuClick
                ? "collapse navbar-collapse bg-dark show"
                : "collapse navbar-collapse bg-dark hide"
            }
          >
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link
                  className="nav-link"
                  to="/"
                  aria-label="WELCOME"
                  tabIndex="0"
                >
                  WELCOME
                </Link>
              </li>
              {getUserValue.FirstName &&
                <li className="nav-item">
                  <a className="nav-link" 
                  href={getUserValue.FirstName ? global.catalogUrl+"/b2r/SAML/login" : global.catalogUrl+"/b2r/landingHome.do?anon=1"}
                  aria-label="REWARDS">
                    REWARDS
                  </a>
                </li>
              }
              {getUserValue.FirstName ? (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onMouseEnter={updateMessagesCount}
                  >
                    MY ACCOUNT
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="up-arrow"></div>
                    <Link
                      className="dropdown-item"
                      to="/myAccount"
                      aria-label="Account Overview"
                    >
                      Account Overview
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/MyAccount/Profile"
                      aria-label="Your Profile"
                    >
                      Your Profile
                    </Link>
                    {/* <Link className="dropdown-item" to="/linked" aria-label="Linked Cards">Linked Cards</Link> */}
                    <Link
                      className="dropdown-item"
                      to="/MyAccount/Communications"
                      aria-label="Communications Preferences"
                    >
                      Communications Preferences
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/MyAccount/Transactions"
                      aria-label="Transactions"
                    >
                      Transactions
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/MyAccount/orderHistory"
                      aria-label="Reward Order History"
                    >
                      Reward Order History
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/messaging"
                      aria-label="Messages"
                    >
                      {unreadCount !== 0
                        ? `${unreadCount} New Messages`
                        : "Messages"}{" "}
                    </Link>
                  </div>
                </li>
              ) : (
                <li className="nav-item dropdown display-none">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-label="My Account"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onMouseEnter={updateMessagesCount}
                  >
                    MY ACCOUNT
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  ></div>
                </li>
              )}
              {getUserValue.FirstName &&
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-label="HELP"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    HELP
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="up-arrow"></div>
                    <Link className="dropdown-item" to="/Help/faqs" aria-label="FAQs">
                      FAQs
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/Help/contactUs"
                      aria-label="Contact Us"
                    >
                      Contact Us
                    </Link>
                  {/*  {getUserValue.FirstName ? (
                      <a
                        className="dropdown-item"
                        href="https://www.pnc.com/content/dam/pnc-com/pdf/personal/CreditCards/points-rewards.pdf"
                        target="_blank"
                        aria-label="Terms and Conditions"
                      >
                        Terms and Conditions
                      </a>
                    ) : (
                      ""
                    )} */}
                    {/* <a className="dropdown-item" href="#" aria-label="Terms and Conditions">Terms and Conditions</a> */}
                  </div>
                </li>
              }
            </ul>
            <div className="pre-header-container">
              <ul>
                <li>
                  <a>PNC.com</a>
                </li>
                <li>
                  <a>Personal</a>
                </li>
                <li>
                  <a>Small Business</a>
                </li>
                <li>
                  <a>Corporate &amp; Institutional</a>
                </li>
                <li>
                  <a>About Us</a>
                </li>
              </ul>
            </div>
          </div>

          {getUserValue.FirstName ? (
            <div className="pnc-sign-in">
              <a href="/Logout/Logout">
                <span className="pnc-sign-in-text" aria-hidden="true">
                  SIGN OUT
                </span>
                <img
                  className="sign-in"
                  src="/images/icon-signin.svg"
                  alt="Sign Out"
                />
              </a>
            </div>
          ) : (
            /*  <div className="pnc-sign-in dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-label="HELP"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <span className="pnc-sign-in-text" aria-hidden="true">
                  SIGN IN
                </span>
                <img
                  className="sign-in"
                  src="/images/icon-signin.svg"
                  alt="Sign In"
                />
              </a>
              <div
                className="logged-out dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <p>Welcome Back</p>
                <form action="">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Username"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                    />
                  </div>
                  <button type="button" className="btn btn-primary">
                    Sign In &gt;
                  </button>
                </form>
              </div>
            </div> */
            ""
          )}
        </div>
      </nav>
    </div>
  );
};

export default Header;
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import $ from "jquery";
import ReactSnackBar from "react-js-snackbar";
import { useStoreState } from "easy-peasy";
import config from "../../configs/config";
import {read_cookie} from "../../read_cookie"

const MyCommunication = (props) => {
  document.title = props.title;
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    clearError,
    formState,
  } = useForm();
  const watchEmail = watch("email", "");
  const [checkedState, setcheckedState] = useState(true);
  const [loading, setloading] = useState(false);
  const [optsData, setoptsData] = useState([]);
  const getUserValue = useStoreState((state) => state.user.userInfo);
  let profile = getUserValue ? getUserValue : [];
  const URL = config.qa.node_url;
  const [Show, setShow] = useState(false);

  const getopts = () => {
    $('[data-toggle="tooltip"]').tooltip();
    $("input.disablecopypaste").bind("copy paste", function (e) {
      e.preventDefault();
    });
    setloading(true);
    let profileId = read_cookie('profileId');
    let apiUrl = `opts?profileId=${profileId}`;
    let requestData = {
      ProfileId: profileId,
    };
    let header = { programcode: "RTL_TVL", token: sessionStorage.accessToken };
    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        setloading(false);
        if (response.data) {
          let opts = response.data;          
          opts.ElectronicDisclosure === "Y"
            ? setcheckedState(true)
            : setcheckedState(false);
          $("#email").val(opts.Email);
          setoptsData(opts);
        } 
      })
      .catch(function (error) {
        setloading(false);
        $('[data-toggle="tooltip"]').tooltip();      
      });
  };

  useEffect(() => {
    getopts();
  }, []);

  const getValue = (value) => {
    if (value !== watchEmail) {
      return false;
    } else {
      return true;
    }
  };
  const updateCheckbox = () => {
    setcheckedState(!checkedState);
  };
  const emailMatch = (e) => {
    if (e.target.value != "" && e.target.value !== watchEmail) {
      setError("confirmEmail", "notMatch");
    } else {
      clearError("confirmEmail");
    }
  };

  const getPreferdEmail = () => {
    let prefEmail = "";
    if(getUserValue.hasOwnProperty('Emails')){
      const prefEmailArr = getUserValue.Emails.filter(el=>{
        return el.IsPreferred == true;
      })
      if(prefEmailArr.length > 0){
        prefEmail = prefEmailArr[0].EmailAddress
      }      
    }
    return prefEmail;
  }

  const onSubmit = (data) => {
    if (data.email === data.confirmEmail) {
      setloading(true);

      let profileId = profile.ProfileId;
      let apiUrl = `communication?profileId=${profileId}`;
      let optInOut = checkedState ? "Y" : "N";
      let now = new Date();
      let ActivityDate = now.toISOString();
      let requestData = {
        Emails: [
          {
            EmailAddress: data.email,
            ActivityDate: ActivityDate,
            IsPreferred: true,
            JsonExternalData: {
              ElectronicDisclosure: optInOut,
            },
          },
        ],
      };
      let header = {
        programcode: "RTL_TVL",
        token: sessionStorage.accessToken,
      };
      axios
        .put(URL + apiUrl, requestData, {
          headers: header,
        })
        .then(function (response) {
          setloading(false);
          setShow(true);
          setTimeout(() => {
            setShow(false);
            document.getElementById("formMycommunication").reset();
            getopts();
          }, 5000);
          return response;
        })
        .catch(function (error) {
          setloading(false);
          return error;
        });
    }
  };

  return (
    <div className="container">
      {loading ? <div className="loader"></div> : false}
      <div className="row">
        <div className="offset-md-2 col-md-8">
          <div className="row pnc-my-coomunication">
            <div className="col-md-12">
              <h3 aria-level="1">My Communications</h3>
              <p>
                Want to hear more from PNC Cash Unlimited? Choose to receive
                emails below and feel free to change your preferences whenever
                you want.
              </p>
              <div className="pnc-mycom-form">
                <p className="common-question">Paperless Delivery</p>
                <p>
                  Be sure to keep the email address in your profile current in
                  order to receive emails from us. It is important that we keep
                  you up-to-date with our latest terms and conditions. If you
                  prefer to receive the updates electronically to the email
                  address saved in your profile, please choose that option
                  below.
                </p>

                <form
                  id="formMycommunication"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="electronic-program"
                        role="check-box"
                        aria-label="check-box"
                        checked={checkedState}
                        onChange={updateCheckbox}
                      />
                      <label
                        className="electronic-program-check"
                        htmlFor="electronic-program"
                      >
                        Yes, please send me electronic program disclosures.
                      </label>
                    </div>

                    <div className="pnc-mycom-mail">
                      <div
                        className={
                          errors.email ? "form-group has-error" : "form-group"
                        }
                      >
                        <label htmlFor="email" className="disableLabel">
                          Email *
                        </label>
                        <span className="pnc-tooltip">
                          <span
                            data-toggle="tooltip"
                            data-placement="right"
                            title=""
                            data-original-title="Email Requirements: This is the email you chose for contact purposes."
                          >
                            <img src="/images/tootip.svg" alt="tooltip" />
                          </span>
                        </span>
                        <input
                          id="email"
                          name="email"
                          autoComplete="off" 
                          className="form-control disablecopypaste"
                          defaultValue={
                            getUserValue.Emails
                              ? getPreferdEmail()
                              : ""
                          }
                          type="text"
                          data-email="email"
                          data-email-error="You have entered an invalid email address!"
                          data-required-error="Enter valid Email Address"
                          ref={register({
                            required: true,
                            pattern:
                              /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/i,
                          })}
                        />
                        <div className="help-block with-errors">
                          {errors.email &&
                            errors.email.type === "required" &&
                            "Please enter a valid email address"}
                          {errors.email &&
                            errors.email.type === "pattern" &&
                            "You have entered an invalid email address!"}
                        </div>
                      </div>
                      <div
                        className={
                          errors.confirmEmail
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label htmlFor="confirmEmail" className="disableLabel">
                          Confirm Email *
                        </label>
                        <input
                          id="confirmEmail"
                          name="confirmEmail"
                          autoComplete="off"
                          ref={register({
                            required: true,
                            validate: (value) => getValue(value),
                          })}
                          className="form-control disablecopypaste"
                          type="text"
                          data-email="email"
                          data-email-error="You have entered an invalid email address!"
                          data-emailmatch="emailMatch"
                          data-emailmatch-error="Email does not match!"
                          required=""
                          data-required-error="Enter valid Email Address"
                        />
                        <div className="help-block with-errors">
                          {errors.confirmEmail &&
                            errors.confirmEmail.type === "required" &&
                            "The email addresses entered do not match. Please try again."}
                          {errors.confirmEmail &&
                            errors.confirmEmail.type === "validate" &&
                            "The email addresses entered do not match"}
                        </div>
                      </div>
                    </div>
                    <div className="pnc-btn-wrap">
                      <button
                        type="reset"
                        onClick={() => getopts()}
                        className="btn cancel"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn update">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactSnackBar Icon={""} Show={Show}>
        Your Profile Has Been Updated
      </ReactSnackBar>
    </div>
  );
};

export default MyCommunication;

var config = {
  qa: {
    node_url: "/",
  },
  prod: {
    node_url: "/",
  },
};

export default config;

import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { useStoreState } from "easy-peasy";

import config from "../configs/config";
import { useHistory } from "react-router-dom";

const SAML = (props) => {
  let history = useHistory();
  const getUserValue = useStoreState((state) => state.user.userInfo);
  const urlParams = new URLSearchParams(window.location.search);
  const SAMLRequest = urlParams.get("SAMLRequest");
  const RelayState = urlParams.get("RelayState");

  const [recievedRequest, setrecievedRequest] = useState(false);
  const [posturl, setUrl] = useState("");
  const [samlRes, setSamlres] = useState("");


  useEffect(() => {
    if (recievedRequest === false && getUserValue !== "") {
      setrecievedRequest(true)
      const URL = config.qa.node_url;
      let apiUrl = `b2rdecrypt`;
      let requestData = {
        SAMLRequest,
        RelayState,
        getUserValue,
      };
      let header = { programcode: "RTL_TVL", token: sessionStorage.accessToken };
      axios
        .post(URL + apiUrl, requestData, {
          headers: header,
        })
        .then((response) => {
          if (response && response.data) {
            const { url, SAMLResponse, RelayState } = response.data;
            setUrl(url);
            setSamlres(SAMLResponse);
            if(SAMLResponse){
              document.myForm.submit();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // else{
    //   history.push("/errorPage")
    // }
  });



  return (
    <>
    <div className="loader"></div>
    <section className="pnc-help-section d-none">
      <div className="container">Loading.....</div>
      <form id="myForm" name="myForm" action={DOMPurify.sanitize(posturl)} enctype="application/x-www-form-urlencoded" method="post">
        <div>
        <textarea name="SAMLResponse" value={samlRes} />          
        </div>
        <br />
        <div>        
        <textarea name="RelayState" value={RelayState} />     
        </div>       
        <input type="submit" value="Submit!" />
      </form>
    </section>
    </>
  );
};

export default SAML;

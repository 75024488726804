import React from "react";
import { StoreProvider } from "easy-peasy";
import Store from "./service/store";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import Contact from "./components/contact/contact";
import MyProfile from "./components/myProfile/myProfile";
import MyCommunication from "./components/myCommunication/myCommunication";
import MyAccount from "./components/myAccount/myAccount";
import MessageDetails from "./components/messageDetails/messageDetails";
import Transaction from "./components/transaction/transaction";
import OrderHistory from "./components/orderHistory/orderHistory";
import RewardMessages from "./components/rewardMessage/rewardMessages";
import Resp from "./components/Redirect";
import { createBrowserHistory } from "history";
import Faq from "./components/faq/faq";
import SAML from "./components/bakktSaml";
import Logout from "./components/logout/logout";
import Confirmation from './components/confirmation/confirmation';
import Notfound from "./components/notfound";
import Auth from "./components/auth";
import CLickBack from "./components/confirmation/clickback";
import ErrorPage from "./components/errorPage";
import MaintenancePage from "./components/maintenancePage";

const customHistory = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.getAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      )
    }
  />
);

Auth.authenticate();

function App() {
  let catalogUrl;
  if(process.env.REACT_APP_ENV == "production"){
    catalogUrl = process.env.REACT_APP_CATALOG_URL 
  }
  else{
    catalogUrl = process.env.REACT_APP_CATALOG_URL_QA
  }
  global.catalogUrl = catalogUrl;
  return (
    <React.Fragment>
      <StoreProvider store={Store}>
        <Router forceRefresh={true} history={customHistory}>
          <Header />
          <section className="pnc-wrapper">
          <Switch>
          {process.env.REACT_APP_MAINTANENCE === "true" ? <Route path="*"  component={() => <MaintenancePage title="PNC Points | MaintenancePage"/>} />
                : false}
            <Route exact path="/home" component={() => <Home title="PNC Cash Unlimited | Home" />} />
            <Route
              exact
              path="/"
              component={() => <Home title="PNC Cash Unlimited | Home" />} 
            />
            <Route
              exact
              path="/contact"
              component={() => (
                <Contact title="PNC Cash Unlimited | Contact Us" />
              )}
            />
             <Route
              exact
              path="/Help/contactUs"
              component={() => (
                <Contact title="PNC Cash Unlimited | Contact Us" />
              )}
            />
            {/* <Route
              exact
              path="/help"
              exact
              component={() => <Help title="PNC Premier Traveler | Help" />}
            /> */}
            {/* <Route exact path="/retrive-username" exact component={() => <RetriveUsername title="PNC Premier Traveler | retrive-username" />} /> */}
            <PrivateRoute
              exact
              path="/MyAccount/Communications"
              component={() => (
                <MyCommunication title="PNC Cash Unlimited | My Communication" />
              )}
            />
            <PrivateRoute
              exact
              path="/myAccount"
              component={() => (
                <MyAccount title="PNC Cash Unlimited | myAccount" />
              )}
            />
            <PrivateRoute
              exact
              path="/messageDetails/:type/:id"
              component={() => (
                <MessageDetails title="PNC Cash Unlimited | Message Details" />
              )}
            />
            <PrivateRoute
              exact
              path="/MyAccount/Transactions"
              component={() => (
                <Transaction title="PNC Cash Unlimited | Transaction" />
              )}
            />
            <PrivateRoute
              exact
              path="/MyAccount/orderHistory"
              component={() => (
                <OrderHistory title="PNC Cash Unlimited | Order History" />
              )}
            />
            <PrivateRoute
              exact
              path="/MyAccount/Profile"
              component={() => (
                <MyProfile title="PNC Cash Unlimited | My Profile" />
              )}
            />
            <PrivateRoute
              exact
              path="/messaging"
              component={() => (
                <RewardMessages title="PNC Cash Unlimited | Messages" />
              )}
            />
            {/* <Route exact path="/forgotpassword" exact component={() => <ForgotPassword title="PNC Premier Traveler | ForgotPassword" forgotData={forgotData} />} /> */}
            {/* <Route exact path="/linked" exact component={() => <LinkedCards title="PNC Premier Traveler | Linked Card" />} /> */}
            <Route
              exact
              path="/Help/faqs"
              component={() => <Faq title="PNC Cash Unlimited | FAQ" />}
            />
            <Route exact path="/SAML/b2rLogin" component={() => <SAML />} />
            <Route exact path="/confirmation" component={() => <Confirmation title="PNC Cash Unlimited | Email Address Confirmation"/>} />
            <Route exact path="/clickback/confirmation/" component={() => <CLickBack title="PNC Cash Unlimited | Email Address Confirmation"/>} />
            <Route exact path="/resp/:id" component={() => <Resp />} />
            <Route exact path="/Logout/Logout" component={() => <Logout />} /> 
            <Route exact path="/errorPage"  component={() => <ErrorPage title="PNC Cash Unlimited | Error"/>} />
            <Route path="*"  component={() => <Notfound title="PNC points | System Error"/>} />  
            </Switch>
          </section>
          <Footer />
        </Router>
      </StoreProvider>
    </React.Fragment>
  );
}

export default App;

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalExample = (props) => {
  const {
    buttonLabel,
    className,
    currSt,
    updateSt
  } = props;

  const [modal, setModal] = useState(true);

  const toggle = () => {
    
    //setModal(!modal)
    updateSt();
    };

  return (
    <div>
      <Modal isOpen={modal} toggle={currSt} className="modal-lg">
        <ModalBody>
        <div id="confirmWrapper">
                <div class="confirmTitle">Types of qualifying purchases.</div>
                <div class="confirmText">
                        
                    "Qualifying Purchases" consist of the following types of purchases, made with a Visa credit card enrolled in PNC points and processed or submitted through the Visa U.S.A. Inc. payment system: 
                    <ul>
                        <li>Purchases you sign for (purchases using your PIN code do not earn points) </li>
                        <li>Internet purchases </li>
                        <li>Phone and mail order purchases </li>
                        <li>Bill payments </li>
                        <li>Contactless purchases (purchases made by holding your Visa card or other device up to a secure reader instead of swiping your card) </li>
                        <li>Small dollar purchases for which the merchant does not require you to sign</li>
                    </ul>
                    Certain exclusions apply.
                        

                 
                    </div>
                    <div class="confirmTitle">                        Use your Visa card for in-store purchases.
                    </div>

                    
                    
                    
                    
                    
                                </div>
            </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalExample;